import React, { useState, useEffect } from 'react';
import WeverCollector from 'wever-collector';
import { Spinner } from 'reactstrap';

import 'wever-collector/dist/styles/bootstrap.css';
import './index.css';

function App() {
  const [diag , setDiag] = useState();
  const [email , setEmail] = useState();
  const [show , setShow] = useState(false);
  const [locale , setLocale] = useState('fr');
  const [token , setToken] = useState('fr');

  const onChangeEmail = (e) => {

    if (e.target.value && e.target.value.indexOf('@') !== -1 && e.target.value.indexOf('.fr') !== -1) {
      setEmail(e.target.value);
    }
  }

  const showDiag = () => {
    setShow(true);
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailStr = urlParams.get('email');
    const diagId = parseInt(urlParams.get('diag'), 10);
    const myLocale = urlParams.get('locale');
    setToken(urlParams.get('token'));

    console.debug('Informations : ', emailStr, diagId);

    if (myLocale) {
      setLocale(myLocale || 'fr');
    }

    setEmail(emailStr);
    setDiag(diagId);
  }, [email]);

  useEffect(() => {}, [email]);

  if (!diag) {
      return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center"> 
          <Spinner color="warning" size="lg" />
        </div>
      );
  }

  console.log('infos: ', email, diag);

  return null !== diag && (
    <div className="App">
      <WeverCollector  lang={locale} locale={locale} email={email} diagId={diag} />
    </div>
  );
}

export default App;
